import React from 'react';
import { graphql } from 'gatsby';
//
import { AdvicePage } from 'arvesta-ui-library';
import { ContentfulAdvice, ContentfulAdvicesSection, ContentfulSettings } from '../generated/gatsby.types';
import Layout from '../components/layout';
import extractShortDescription from '../transforms/ShortDescription';
import transformProductsServicesSection from '../transforms/ProductsServices';
import transformProductCategoriesSection from '../transforms/ProductCategories';
import extractFileInforamtion from '../transforms/File';

type AdviceQueryType = {
  contentfulAdvice: ContentfulAdvice;
  contentfulAdvicesSection: ContentfulAdvicesSection;
  contentfulCookieBanner: any;
  site: any;
  contentfulSettings: ContentfulSettings;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type AdviceProps = {
  data: AdviceQueryType;
  pageContext: PageContextType;
};

const Advice = ({ data, pageContext }: AdviceProps) => {
  const {
    title,
    shortDescription,
    image,
    contentsSection,
    featuredProducts,
    productCategories,
    document,
    updatedAt,
  } = data.contentfulAdvice;

  return (
    <Layout
      pageTitle={title as string}
      pageDescription={extractShortDescription(shortDescription as any)}
      pageImage={image || undefined}
      pageLocale={pageContext.node_locale}
      pageType="advice"
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug={data.contentfulAdvice.slug ? data.contentfulAdvice.slug : ''}
      contentfulSettings={data.contentfulSettings}
      pageSettings={data.contentfulAdvice.metaTags}
      cookieBannerTitle={data.contentfulCookieBanner.title}
      cookieBannerDescription={data.contentfulCookieBanner.description}
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
      updatedAt={updatedAt}
      ogPageType="article"
    >
      <AdvicePage
        title={title as string}
        shortDescription={extractShortDescription(shortDescription as any)}
        contentsSection={{ data: contentsSection ? contentsSection.json : null }}
        image={image}
        featuredProducts={featuredProducts ? transformProductsServicesSection(featuredProducts) : null}
        categoriesSection={productCategories ? transformProductCategoriesSection(productCategories) : null}
        documentDownload={extractFileInforamtion(document)}
      />
    </Layout>
  );
};

export default Advice;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(node_locale: { eq: $node_locale }) {
      title
      description {
        json
      }
    }
    contentfulAdvice(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      document {
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      image {
        title
        alt: altText
        asset {
          fluid(resizingBehavior: FILL, quality: 85) {
            ...QueryImageFluid
          }
        }
        socialImage: asset {
          fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
            ...QueryImageFixed
          }
        }
      }
      author
      shortDescription {
        text: shortDescription
      }
      contentsSection {
        json
      }
      featuredProducts {
        title
        shortDescription {
          text: shortDescription
        }
        products {
          slug
          title
          shortDescription {
            text: shortDescription
          }
          price
          image {
            asset {
              fluid {
                ...QueryImageFluid
              }
            }
          }
          id
        }
        cta {
          externalUrl
          internalEntry {
            ...FragmentNavigationLink
          }
          title
        }
      }
      productCategories {
        ...QueryProductCategoriesSection
      }
    }

    contentfulAdvicesSection {
      title
      shortDescription {
        text: shortDescription
      }
      advices {
        ... on ContentfulAdvice {
          slug
          title
          shortDescription {
            text: shortDescription
          }
          image {
            asset {
              fixed(height: 670, width: 670, quality: 80) {
                ...QueryImageFixed
              }
            }
          }
        }
      }
    }

    contentfulSettings(node_locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }
  }
`;
